<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-container>
      <!-- GENERAL SETTINGS -->
      <v-row>
        <v-col>
          <v-card style="margin: 25px">
            <v-toolbar dense flat>
              <v-toolbar-title>{{ $store.getters.translate("general_settings") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn icon @click="save()" color="primary">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider/>
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("release_version") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-text-field v-model="record.release_version" dense single-line/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("company") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-text-field v-model="record.name" dense single-line/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("phone") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-text-field v-model="record.phone" dense single-line/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("website") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-text-field v-model="record.website" placeholder="https://" dense single-line/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("email") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-text-field :placeholder="$store.getters.translate('email')" v-model="record.email" dense single-line/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("addresses") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <base-addresses v-model="record.addresses" @change="save()" hide-details/>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("logo") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-file-input
                    class="mt-2"
                    v-if="!record.logo"
                    accept="image/*"
                    :label="$store.getters.translate('logo')"
                    show-size
                    @change="saveLogo"
                    v-model="logo"
                    dense
                    single-line/>
                  <div class="d-flex flex-wrap" v-else>
                    <v-img
                      max-height="100"
                      max-width="100"
                      contain
                      :src="record.logo.full_path"
                      style="border: 1px solid lightgrey"
                      class="image-color">
                    </v-img>
                    <v-btn
                      max-width="150"
                      text
                      color="red"
                      class="mt-2 image-color"
                      @click="deleteLogo()">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("login_background_image") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-file-input
                    class="mt-2"
                    v-if="!record.background_image"
                    accept="image/*"
                    :label="$store.getters.translate('login_background_image')"
                    show-size
                    @change="saveImage"
                    v-model="file"
                    dense
                    single-line/>
                  <div class="d-flex flex-wrap" v-else>
                    <v-img
                      max-height="100"
                      max-width="100"
                      contain
                      :src="record.background_image.full_path"
                      style="border: 1px solid lightgrey"
                      class="image-color"/>
                    <v-btn
                      max-width="150"
                      text
                      color="red"
                      class="mt-2 image-color"
                      @click="deleteImage()">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("primary_color") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-sheet
                        :color="record.primary_color"
                        elevation="1"
                        height="50"
                        rounded
                        width="50"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-2 image-color"/>
                    </template>
                    <v-color-picker
                      mode="hexa"
                      dot-size="25"
                      swatches-max-height="200"
                      v-model="record.primary_color"/>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("secondary_color") }}
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="6" class="wrap-input-form">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-sheet
                        :color="record.secondary_color"
                        elevation="1"
                        height="50"
                        rounded
                        width="50"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-2 image-color"/>
                    </template>
                    <v-color-picker
                      mode="hexa"
                      dot-size="25"
                      swatches-max-height="200"
                      v-model="record.secondary_color"/>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="wrap-label-form">
                  <v-subheader>
                    {{ $store.getters.translate("send_notification") }}
                  </v-subheader>
                </v-col>
                <v-col>
                  <base-notification modal_name="base_notification" @refresh="load()" user_id="all"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="margin: 18px">
                  <base-editor class="mb-5" :label="$store.getters.translate('login_welcome')" v-model="record.welcome" height="500"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="margin: 18px">
                  <base-editor class="mb-5" :label="$store.getters.translate('register_email')" v-model="record.register_email" height="500"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="margin: 18px">
                  <base-editor class="mb-5" :label="$store.getters.translate('password_reset_email')" v-model="record.password_reset_email" height="500"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- AUDITS -->
      <v-row v-if="canManageAudits()">
        <v-col>
          <v-card :loading="loading" :disabled="loading" style="margin-left: 25px; margin-right: 25px;">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-toolbar class="no-left-padding" dense flat>
                    <v-toolbar-title class="ma-0 pa-0">
                      {{ $store.getters.translate('audits') }}
                      <small class="ml-1 text--lighten-3">
                        ({{ total_audits }})
                      </small>
                    </v-toolbar-title>
                    <v-spacer/>
                  </v-toolbar>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-text-field
                                v-model="search"
                                v-debounce:230ms.lock="launchSearch"
                                @click:clear="clearSearch"
                                :label="$store.getters.translate('search')"
                                single-line
                                hide-details
                                clearable
                                append-icon="mdi-magnify">
                            </v-text-field>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-select
                                :label="$store.getters.translate('user')"
                                v-model="selected_user"
                                :items="$lodash.sortBy(users, 'text')"
                                clearable/>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-select
                                :label="$store.getters.translate('model_type')"
                                v-model="selected_model"
                                :items="$lodash.sortBy(models, 'text')"
                                clearable/>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-select
                                :label="$store.getters.translate('event')"
                                v-model="selected_event"
                                :items="$lodash.sortBy(events, 'text')"
                                clearable/>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-datetime-picker
                                @input="loadAudits()"
                                :label="$store.getters.translate('starting_time')"
                                v-model="period_start"
                                timeFormat="HH:mm"
                                dateFormat="dd-MM-yyyy"
                                :clearText="$store.getters.translate('clear')"
                                :okText="$store.getters.translate('save')"
                                :timePickerProps="timepicker">
                              <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>mdi-clock-outline</v-icon>
                              </template>
                            </v-datetime-picker>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                    <v-col>
                      <template>
                        <v-card flat>
                          <v-card-text>
                            <v-datetime-picker
                                @input="loadAudits()"
                                :label="$store.getters.translate('ending_time')"
                                v-model="period_end"
                                timeFormat="HH:mm"
                                dateFormat="dd-MM-yyyy"
                                :clearText="$store.getters.translate('clear')"
                                :okText="$store.getters.translate('save')"
                                :timePickerProps="timepicker">
                              <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>mdi-clock-outline</v-icon>
                              </template>
                            </v-datetime-picker>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                  <v-data-table
                      :headers="headers.audit"
                      :items="getAudits"
                      :loading="loading"
                      item-key="id"
                      :options.sync="options"
                      :server-items-length="total_audits"
                      :footer-props="footer_props"
                      :items-per-page.sync="options.itemsPerPage">
                    <template v-slot:item.auditable_id="{ item }">
                      <a v-if="models_with_links.includes(item.auditable_type) && !item.deleted_at" @click="openModel(item)">
                        {{ $lodash.truncate(getModelName(item), { length: 30 }) }}
                      </a>
                      <template v-else>
                        {{ $lodash.truncate(getModelName(item), { length: 30 }) }}
                      </template>
                    </template>
                    <template v-slot:item.old_values_json="{ item }">
                      <template v-if="item.event === 'updated'">
                        <div v-for="(value, key) in item.old_values_json" :key="key">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                      <template v-else>-</template>
                    </template>
                    <template v-slot:item.new_values_json="{ item }">
                      <template v-if="item.event === 'updated'">
                        <div v-for="(value, key) in item.new_values_json" :key="key">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                      <template v-else>-</template>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <template v-if="can_restore.includes(item.auditable_type) && item.event === 'deleted' && item.deleted_at && item.deleted_at !== 'deleted'">
                        <v-icon @click="restoreRecord(item)" small class="mr-2">mdi-restore</v-icon>
                        <v-icon @click="deleteRecord(item)" small>mdi-delete</v-icon>
                      </template>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
      <!-- JOBS -->
      <v-row>
        <v-col>
          <v-card :loading="loading" :disabled="loading" style="margin-left: 25px; margin-right: 25px;">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-toolbar class="no-left-padding" dense flat>
                    <v-toolbar-title class="ma-0 pa-0">
                      {{ $store.getters.translate('job_queue') }}
                      <small class="ml-1 text--lighten-3">({{ jobs.length }})</small>
                    </v-toolbar-title>
                  </v-toolbar>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                      :headers="headers.job_queue"
                      :items="jobs"
                      :loading="loading"
                      :items-per-page="200">
                    <template v-slot:item.name="{ item }">
                      {{ $store.getters.translate(item.name) }}
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <edit-note modal_name="edit_note" :module_name="page.name" ref="edit_note" @refresh="load()"/>
    <document-preview modal_name="document_preview" ref="document_preview"/>
    <edit-tag modal_name="edit_tag" :module_name="page.name" ref="edit_tag" @refresh="load()"/>
  </v-container>
</template>

<script>
import EditNote from "../notes/Edit";
import EditTag from "../tags/Edit";
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentPreview from "../../components/commonComponents/DocumentPreview";

export default {
  components: {
    BaseBreadcrumb,
    EditNote,
    EditTag,
    DocumentPreview,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("settings"),
        name: "settings",
      },
      record: {},
      file: null,
      logo: null,
      loading: false,
      search: "",
      jobs: [],
      all_audits: [],
      audits: [],
      total_audits: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
      },
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200],
        rowsPerPageText: "Rows per page:",
      },
      headers: {
        audit: [],
        job_queue: [{
            text: this.$store.getters.translate('id'),
            align: "start",
            sortable: true,
            value: 'id',
          },
          {
            text: this.$store.getters.translate('name'),
            align: "start",
            sortable: true,
            value: 'name',
          },
          {
            text: this.$store.getters.translate('date'),
            align: "start",
            sortable: true,
            value: 'date',
          }],
      },
      users: [],
      selected_user: null,
      models: [],
      selected_model: null,
      events: [],
      selected_event: null,
      timepicker: {
        format: "24hr",
      },
      period_start: null,
      period_end: null,
      can_restore: [],
      models_with_links: [],
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue("audit_options")) {
      this.options = helpFunctions.getLocalStorageValue("audit_options");
    }
    if(helpFunctions.getLocalStorageValue("audit_search")) {
      this.search = helpFunctions.getLocalStorageValue("audit_search");
    }
    this.can_restore = helpFunctions.can_restore;
    this.models_with_links = helpFunctions.models_with_links;
    this.load();
    this.getFields("audit");
    this.loadAudits();
    this.loadJobs();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async loadAudits() {
      if(!this.period_start) {
        this.period_start = new Date(Date.now() - 7*24*60*60*1000);
      }
      if(!this.period_end) {
        this.period_end = new Date();
      }
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/getaudits" + "?period_start=" + this.period_start.getTime() + "&period_end=" + this.period_end.getTime())
          .then((response) => {
            this.loading = false;
            this.all_audits = response.data.data;
            this.users = this.composeFields(response.data.users);
            this.models = this.composeFields(response.data.models);
            this.events = this.composeFields(response.data.events);
            if(this.search && this.search.length > 2 && this.headers.audit[0]['value'] !== 'search_score') {
              this.headers.audit.splice(0, 0, {
                text: this.$store.getters.translate('search_score'),
                align: "start",
                sortable: true,
                value: 'search_score',
              });
            }
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async loadJobs() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/getqueue")
          .then((response) => {
            this.loading = false;
            this.jobs = response.data;
          }).catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    composeFields(fields) {
      let response = [];
      Object.entries(fields).forEach(([key,value]) => {
        response.push({
          value: key,
          text: this.$store.getters.translate(value),
        });
      });
      return response;
    },
    showRecords() {
      var sorted_array = this.all_audits;
      if(this.period_start || this.period_end) {
        sorted_array = this.sortByDate(sorted_array);
      }
      if (this.selected_user) {
        sorted_array = this.sortByUserModelEvent(sorted_array, 'user_id', this.selected_user);
      }
      if (this.selected_model) {
        sorted_array = this.sortByUserModelEvent(sorted_array, 'auditable_type', this.selected_model);
      }
      if (this.selected_event) {
        sorted_array = this.sortByUserModelEvent(sorted_array, 'event', this.selected_event);
      }
      if (this.search && this.search.length > 2) {
        var fuse_keys = [];
        fuse_keys["user_name"] = 1;
        fuse_keys["event"] = 1;
        fuse_keys["auditable_type"] = 1;
        fuse_keys["auditable_name"] = 1;
        sorted_array = helpFunctions.searchInSortedArray(sorted_array, fuse_keys, this.search, 'all', false);
      }
      //if ((!this.search || this.search.length < 3) && this.options.sortBy[0]) {
      if (this.options.sortBy[0]) {
        sorted_array = helpFunctions.sortByColumn(sorted_array, this.options.sortBy[0], this.options.sortDesc[0]);
      }
      this.audits = [];
      this.total_audits = sorted_array.length;
      let start = (this.options.page - 1) * this.options.itemsPerPage;
      var end = this.options.page * this.options.itemsPerPage;
      if (end > sorted_array.length) {
        end = sorted_array.length;
      }
      for (let i = start; i < end; i++) {
        this.audits.push(sorted_array[i]);
      }
    },
    sortByDate(sorted_array) {
      var start = new Date(Date.now() - 365*24*60*60*1000);
      if(this.period_start) {
        start = new Date(this.period_start);
      }
      var end = new Date();
      if(this.period_end) {
        end = new Date(this.period_end);
      }
      var response = [];
      for (let i = 0; i < sorted_array.length; i++) {
        const compared_date = new Date(sorted_array[i]['updated_at']);
        if (compared_date > start && compared_date < end) {
          response.push(sorted_array[i]);
        }
      }
      return response;
    },
    sortByUserModelEvent(sorted_array, sort_type, sort_value) {
      var response = [];
      for (let i = 0; i < sorted_array.length; i++) {
        if (sorted_array[i][sort_type] == sort_value) {
          response.push(sorted_array[i]);
        }
      }
      return response;
    },
    restoreRecord(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          var auditable_type = item.auditable_type + "s";
          if(item.auditable_type === 'company') {
            auditable_type = "companies";
          }
          this.$http
            .post(this.$store.getters.appUrl + "v2/" + auditable_type + "/" + item.auditable_id + "/restore")
            .then(() => {
              this.loading = false;
              this.period_end = new Date();
              this.loadAudits();
              return true;
            }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      });
    },
    deleteRecord(item) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          var auditable_type = item.auditable_type + "s";
          if(item.auditable_type === 'company') {
            auditable_type = "companies";
          }
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + auditable_type + "/" + item.auditable_id + "/delete")
            .then(() => {
              this.loading = false;
              this.period_end = new Date();
              this.loadAudits();
              return true;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    getModelName(item) {
      if(item.auditable_name) {
        return item.auditable_name;
      }
      else {
        return item.auditable_id;
      }
    },
    openModel(item) {
      if(item.auditable_type === 'company') {
        this.$router.push("/companies/" + item.auditable_id);
      }
      else if(item.auditable_type === 'note') {
        this.$refs.edit_note.load(item.auditable_id);
      }
      else if(item.auditable_type === 'tag') {
        this.$refs.edit_tag.load(item.auditable_id);
      }
      else if(item.auditable_type === 'document') {
        this.$refs.document_preview.load(item.auditable_id);
      }
      else {
        this.$router.push("/" + item.auditable_type + "s/" + item.auditable_id);
      }
    },
    clearSearch() {
      this.search = "";
      if(this.headers.audit[0]['value'] === 'search_score') {
        this.headers.audit.splice(0, 1);
      }
      this.showRecords();
    },
    launchSearch() {
      if(this.search && this.search.length > 2) {
        if(this.headers.audit[0]['value'] !== 'search_score') {
          this.headers.audit.splice(0, 0, {
            text: this.$store.getters.translate('search_score'),
            align: "start",
            sortable: true,
            value: 'search_score',
          });
        }
      }
      else if(this.headers.audit[0]['value'] === 'search_score') {
        this.headers.audit.splice(0, 1);
      }
      this.showRecords();
    },
    saveImage() {
      this.loading = true;
      const formData = new FormData();
      formData.append("background_image", this.file, "name.jpeg");
      this.$http
        .post(this.$store.getters.appUrl + "v2/settings/file?id=" + this.record.id, formData, { headers: { "Content-Type": "multipart/form-data" }})
        .then(() => {
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/settings/file/delete?id=" + this.record.id)
        .then(() => {
          this.load();
          this.file = null;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    saveLogo() {
      this.loading = true;
      const formData = new FormData();
      formData.append("logo", this.logo, "name.jpeg");
      this.$http
        .post(this.$store.getters.appUrl + "v2/settings/logo?id=" + this.record.id, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteLogo() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/settings/logo/delete?id=" + this.record.id)
        .then(() => {
          this.load();
          this.logo = null;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async getFields(module_name) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_in_search=true&model_type=" + module_name)
          .then((response) => {
            response.data.data.forEach((item) => {
              this.headers[module_name].push({
                text: this.$store.getters.translate(item.name),
                align: "start",
                sortable: true,
                value: item.name,
              });
            });
            this.headers[module_name].push({
              text: this.$store.getters.translate("actions"),
              sortable: false,
              align: "right",
              value: "actions",
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    canManageAudits(id = 0) {
      return this.$can("show", this.$createEntity('audit', {id}))
          || this.$can("create", this.$createEntity('audit', {id}))
          || this.$can("edit", this.$createEntity('audit', {id}))
          || this.$can("delete", this.$createEntity('audit', {id}));
    },
  },
  computed: {
    getAudits() {
      return this.audits;
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue("audit_options", this.options);
        this.showRecords();
      },
      deep: true,
    },
    selected_user: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
    },
    selected_model: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
    },
    selected_event: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
    },
    search: {
      handler() {
        helpFunctions.setLocalStorageValue("audit_search", this.search);
        this.options.page = 1;
      },
    },
  },
};
</script>

<style scoped>
.submit-button {
  width: 75%;
}

.wrap-input-form > .v-input {
  padding-right: 16px;
}

@media (max-width: 767px) {
  .wrap-label-form,
  .wrap-input-form {
    margin: 0;
    padding: 0px 10px 0px 10px;
  }
  .wrap-input-form {
    margin: auto;
  }

  .wrap-input-form > .v-input {
    padding: 0;
    margin: 0;
  }

  .wrap-label-form > .v-subheader {
    padding: 0;
  }

  .image-color {
    margin: auto;
  }
}
</style>




