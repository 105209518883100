<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading" height="100%" width="99%">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="closeModal(modal_name)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text style="height: 98vh">
            <v-row style="height: 100%">
              <v-col lg="4" md="12" sm="12" xs="12" style="overflow-y: scroll; height: -webkit-fill-available;">
                <v-card flat height="100%" class="d-flex flex-column">
                  <v-card-text>
                    <template>
                      {{ $store.getters.translate('direct_link_to_document') }}: <span style="color:red;">{{appUrl}}/{{page.name}}?id={{record.id}}</span>
                      <v-btn icon @click="copyLink">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <br/>
                    <template v-if="record.app_upload">
                      <span style="color:red;">{{ $store.getters.translate('uploaded_from_app') }}</span>
                      <br/>
                      <span style="color:red;">By {{ record.creator }}</span>
                      <br/>
                      <v-btn @click="signAndSave()" block color="success" class="mt-5">
                        {{ $store.getters.translate("sign_and_save") }}
                      </v-btn>
                    </template>
                    <template v-for="field in usual_fields">
                      <base-field
                          :isDisabled="!canEdit(record.id || 0)"
                          :key="field.name"
                          :value="record[field.name]"
                          :field="field"
                          @change="usualFieldDataChanged"/>
                    </template>
                    <template v-if="record.custom_fields">
                      <template v-for="field in custom_fields">
                        <base-field
                            :isDisabled="!canEdit(record.id || 0)"
                            :key="field.name"
                            :value="record.custom_fields[field.name]"
                            :field="field"
                            @change="customFieldDataChanged"/>
                      </template>
                    </template>
                    <base-log-field :model_type="page.model" :model_id="record.id"/>
                    <base-row
                        v-if="record.file"
                        :key="record.file.id"
                        :label="$store.getters.translate('file')"
                        :value="record.file.name"
                        :link="true"
                        :hide_copy="true"
                        :deletable="canEdit(record.id || 0)"
                        @delete="deleteFile(record.file)"/>
                    <v-row v-if="!record.file">
                      <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                        <v-file-input
                            :disabled="!canEdit(record.id || 0)"
                            :label="$store.getters.translate('files')"
                            show-size
                            v-model="record.new_file"
                            @change="saveFile"/>
                      </v-col>
                    </v-row>
                    <template v-if="!record.related_assets">
                      <v-toolbar flat dense>
                        {{ $store.getters.translate("linked_assets") }}
                        <v-spacer/>
                        <v-btn v-if="canEdit(record.id || 0)" @click.stop="linkDocument()" block text color="primary" prepend-icon small>
                          {{ $store.getters.translate('link_document_to_asset') }}
                          <v-icon small class="ml-2">mdi-link</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-divider/>
                      <v-list dense>
                        <template v-for="(asset, index) in record.assets">
                          <v-list-item :key="index" color="primary">
                            <v-list-item-content>
                              <v-list-item-title>
                                <a @click="$emit('edit', asset.id)">
                                  {{ asset.name }}
                                  <small v-if="asset.parent_id">({{ asset.platformName }})</small>
                                </a>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn v-if="canEdit(record.id || 0)" small text @click.stop="unlinkAsset(asset.id)">
                                <v-icon color="primary" small>mdi-link-off</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                    </template>
                    <template v-else>
                      <v-toolbar flat dense>
                        {{ $store.getters.translate("latest_revision_linked_assets") }}
                      </v-toolbar>
                      <v-divider/>
                      <v-list dense>
                        <template v-for="(asset, index) in record.related_assets">
                          <v-list-item :key="index" color="primary">
                            <v-list-item-content>
                              <v-list-item-title>
                                <a @click="$emit('edit', asset.id)">
                                  {{ asset.name }}
                                  <small v-if="asset.parent_id">({{ asset.platformName }})</small>
                                </a>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </template>
                    <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
                  </v-card-text>
                  <v-spacer/>
                  <v-footer>
                    <v-btn :disabled="!canEdit(record.id || 0)" @click="save()" block color="success">
                      {{ $store.getters.translate("save") }}
                    </v-btn>
                    <v-btn v-if="!record.related_assets" class="mt-5" :disabled="!canEdit(record.id || 0)" @click="createRevision()" block color="success">
                      {{ $store.getters.translate("create_revision") }}
                    </v-btn>
                    <v-btn class="mt-5" color="red" :disabled="!(canEdit(record.id || 0) && canDelete(record.id || 0))" @click="archive()" block>
                      {{ $store.getters.translate("delete") }}
                    </v-btn>
                  </v-footer>
                </v-card>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <p v-if="blob" align="center" style="height: 100%" class="mt-5">
                  <iframe style="overflow: hidden; height: 90vh; width: 100%; display: block;" ref="preview" :src="blob" frameborder="0"/>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <base-link-asset modal_name="link_asset" ref="link_asset" class="mb-3" @refresh="addAssetIds" :current_assets="record.asset_ids" :module_name="page.name"/>
        </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseLinkAsset from "./BaseLinkAsset";
import BaseLogField from "./BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
export default {
  components: {
    BaseLinkAsset,
    BaseLogField,
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name","asset_id"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("preview"),
        name: "documents",
        model: "document"
      },
      loading: false,
      record: {
        assets: [],
        asset_ids: []
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      blob: null,
      message: this.$store.getters.translate("loading"),
      not_found: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {
        assets: [],
        asset_ids: []
      };
      this.blob = null;
      this.openModal(this.modal_name);
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, id);
      this.record.asset_ids = this.$lodash.map(this.record.assets, 'id');
      if (this.record.file) {
        let file = this.record.file;
        if (file.content_type === "application/pdf") {
          this.preview(file.id);
        } else {
          this.download(file);
        }
      }
      await this.getFields();
    },
    preview(id) {
      if (id) {
        this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + id, {
            responseType: "blob",
          }).then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            this.blob = link.href;
          }).catch((error) => {
            if (error.response.status == 404) {
              this.message = this.$store.getters.translate("nothing_found");
              this.not_found = true;
            }
            this.loading = false;
          });
      }
    },
    addAssetIds(ids) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/linkassets", { document_id: this.record.id, asset_ids: ids })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
            this.load(this.record.id);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/documents/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh");
                  this.load(this.record.id);
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                if (error.response.status == 404) {
                  this.message = this.$store.getters.translate("nothing_found");
                  this.not_found = true;
                }
                this.loading = false;
              });
        }
      }
    },
    signAndSave() {
      this.record.commissioned = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/documents/" + this.record.id, this.record)
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.$emit("refresh");
            this.load(this.record.id);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.message = this.$store.getters.translate("nothing_found");
              this.not_found = true;
            }
            this.loading = false;
          });
    },
    linkDocument() {
      this.$refs.link_asset.load();
    },
    unlinkAsset(asset_id) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .post(this.$store.getters.appUrl + "v2/documents/unlinkasset", { document_id: this.record.id, asset_id: asset_id })
                  .then(() => {
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("success"));
                    this.load(this.record.id);
                  })
                  .catch((error) => {
                    if (this.$store.getters.isLoggedIn) {
                      this.$toasted.error(error);
                    }
                    this.loading = false;
                  });
            }
          });
    },
    createRevision() {
      this.$emit("create", this.record);
      this.closeModal(this.modal_name);
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      });
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/files/" + file.id, {
          responseType: "blob",
        })
        .then((response) => {
          this.loading = false;
          const blob = new Blob([response.data], { type: file.content_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    addDropFile(e) {
      this.loading = true;
      const formData = new FormData();
      formData.append("attachment", e.dataTransfer.files[0]);
      formData.append("document_id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id);
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    saveFile() {
      if (!this.record.new_file) {
        return false;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("attachment", this.record.new_file);
      formData.append("document_id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id);
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
                  .then(() => {
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("success"));
                    this.load(this.record.id);
                  })
                  .catch((error) => {
                    if (this.$store.getters.isLoggedIn) {
                      this.$toasted.error(error);
                    }
                    this.loading = false;
                  });
            }
          });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    copyLink() {
      navigator.clipboard.writeText(process.env.VUE_APP_URL + "/" + this.page.name + "?id=" + this.record.id);
      this.$toasted.success(this.$store.getters.translate("success"));
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    appUrl() {
      return process.env.VUE_APP_URL;
    },
  },
};
</script>